























































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref, Ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingWalkProfileAdvanced',
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    vertical: {
      type: Boolean
    }
  },
  setup(properties) {
    const { isImperial } = useUnit().currentUnit();
    const backgroundsColor: Ref<string[]> = ref(['#FF5E13', '#FFBE13', '#25C090', '#FFBE13', '#FF5E13']);
    const data: Ref<any | null> = ref([]);

    const columns = computed(() => (properties.vertical ? 12 : 6));

    const strideDuration = computed(() => {
      return {
        unit: 'ms',
        name: 'stride-duration',
        left: properties.aggregates.walking_stride_time.left_foot.avg,
        right: properties.aggregates.walking_stride_time.right_foot.avg,
        norms: properties.norms.walking_stride_time,
        indicator: {
          left: properties.aggregates.walking_stride_time.left_foot.indicator,
          right: properties.aggregates.walking_stride_time.right_foot.indicator
        }
      };
    });
    const strideLength = computed(() => {
      return {
        unit: getUnit(EValueTypeUnit.StrideLength),
        name: 'stride-length',
        left: properties.aggregates.walking_stride_length.left_foot[isImperial.value ? 'imp_avg' : 'avg'],
        right: properties.aggregates.walking_stride_length.right_foot[isImperial.value ? 'imp_avg' : 'avg'],
        norms: properties.norms.walking_stride_length,
        indicator: {
          left: properties.aggregates.walking_stride_length.left_foot.indicator,
          right: properties.aggregates.walking_stride_length.right_foot.indicator
        },
        imperial: isImperial.value
      };
    });
    const swing = computed(() => {
      return {
        unit: 'ms',
        name: 'swing-time',
        left: properties.aggregates.walking_swing_time.left_foot.avg,
        right: properties.aggregates.walking_swing_time.right_foot.avg,
        norms: properties.norms.walking_swing_time,
        indicator: {
          left: properties.aggregates.walking_swing_time.left_foot.indicator,
          right: properties.aggregates.walking_swing_time.right_foot.indicator
        }
      };
    });
    const stance = computed(() => {
      return {
        unit: 'ms',
        name: 'contact-time',
        left: properties.aggregates.walking_stance_time.left_foot.avg,
        right: properties.aggregates.walking_stance_time.right_foot.avg,
        norms: properties.norms.walking_stance_time,
        indicator: {
          left: properties.aggregates.walking_stance_time.left_foot.indicator,
          right: properties.aggregates.walking_stance_time.right_foot.indicator
        }
      };
    });

    function valuePosition(value: number, side: string, norms: any, imperial: boolean) {
      const standardDeviation =
        Math.abs(
          norms.bounds.neutral[imperial ? 'imp_max' : 'max'] -
            norms.bounds.neutral[imperial ? 'imp_min' : 'min']
        ) / 2;
      const lowestNorm =
        norms.bounds.very_low[imperial ? 'imp_min' : 'min'] ||
        norms.bounds.very_low[imperial ? 'imp_max' : 'max'] - standardDeviation;
      const highestNorm =
        norms.bounds.very_high[imperial ? 'imp_max' : 'max'] ||
        norms.bounds.very_high[imperial ? 'imp_min' : 'min'] + standardDeviation;

      const percentvalue = ((value - lowestNorm) * 100) / (highestNorm - lowestNorm);
      if (percentvalue < 0) return side === 'right' ? 'calc(100% - 25px)' : 'calc(0% - 25px)';
      if (percentvalue > 100) return side === 'right' ? 'calc(0% - 25px)' : 'calc(100% - 25px)';
      return side === 'right' ? `calc(100% - ${percentvalue}% - 25px)` : `calc(${percentvalue}% - 25px)`;
    }

    function valueStatut(indicator: string) {
      switch (indicator) {
        case 'good':
          return '#25C090';
        case 'warning':
          return '#FFBE13';
        case 'bad':
          return '#FF5E13';
        default:
          return '#2A2A2A';
      }
    }

    watch(
      () => properties.aggregates,
      () => {
        data.value = [strideDuration.value, strideLength.value, swing.value, stance.value];
      },
      { immediate: true, deep: true }
    );

    return {
      // Values
      data,
      backgroundsColor,
      i18n,
      columns,
      // Methods
      valuePosition,
      valueStatut
    };
  }
});
