








































































































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, reactive } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartWalkingWalkProfileDegraded from '@/components/charts/walking/ChartWalkingWalkProfileDegraded.vue';
import ChartWalkingGroupBanner from '@/components/charts/walking/groups/ChartWalkingGroupBanner.vue';
import ChartWalkingFootProgressionAngleCircumduction from '@/components/charts/walking/ChartWalkingFootProgressionAngleCircumduction.vue';
//
import ElementChartUnvailable from '@/components/elements/ElementChartUnvailable.vue';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsComparisonWalkingDegraded',
  components: {
    ChartWalkingGroupBanner,
    ChartWalkingWalkProfileDegraded,
    ChartWalkingFootProgressionAngleCircumduction,
    ElementChartUnvailable
  },
  props: {
    first: {
      type: Object,
      required: true
    },
    second: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { data: patient } = usePatient();

    const integrities = reactive({
      walkingProfile: ['walking_stride_length'],
      footProgressionAngleCircumduction: ['walking_foot_progression_angle_flat_foot', 'walking_swing_width']
    });
    function checkIntegrities(chart: any, analysisIndex: 'first' | 'second') {
      for (const integrity of (integrities as any)[chart]) {
        return !!properties[analysisIndex].data.display_metrics.aggregates[integrity];
      }
    }

    return {
      patient,
      checkIntegrities
    };
  }
});
