<template>
  <VRow class="w-100 justify-center">
    <template v-if="!isAdvancedMode">
      <VCol
        v-for="(chart, index) in simplifiedModeCharts"
        :key="index"
        :cols="isLgAndLarger ? '4' : '6'"
        class="d-flex justify-center"
      >
        <ChartRehabGeneralParameters v-bind="{ chart }" />
      </VCol>
    </template>
    <template v-else>
      <VCol
        v-for="(chart, index) in advancedModeCharts"
        :key="index"
        :cols="isLgAndLarger ? '4' : '6'"
        class="d-flex justify-center"
      >
        <ChartRehabGeneralParameters v-bind="{ chart }" />
      </VCol>
    </template>
  </VRow>
</template>

<script>
// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useVuetifyBreakpoints } from '@/utils/vuetify-breakpoints.utils';
// Import components -------------------------------------------------------------------------------
import ChartRehabGeneralParameters from '@/components/charts/rehab/ChartRehabGeneralParameters.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRehabGroupGeneralParameters',
  components: {
    ChartRehabGeneralParameters
  },
  props: {
    simplifiedModeCharts: {
      type: Array,
      required: true
    },
    advancedModeCharts: {
      type: Array,
      required: true
    },
    isAdvancedMode: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const { isLgAndLarger } = useVuetifyBreakpoints();

    return {
      isLgAndLarger
    };
  }
});
</script>

<style lang="scss" scoped>
.chart-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 14rem;
}

.chart-title {
  font-weight: 600;
  font-size: 0.8rem;
  color: #020c27;
  text-align: center;
}

.data-title {
  font-weight: 600;
  font-size: 0.8rem;

  &--left {
    color: #6642cc;
  }

  &--right {
    color: #398ceb;
  }
}

.rounded-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: white;
  height: 1rem;
  width: 1rem;
  font-size: 0.6rem;
  line-height: 1rem;

  &--left {
    background-color: #6642cc;
  }

  &--right {
    background-color: #398ceb;
  }
}

.theme--light.v-chip:not(.v-chip--active) {
  background: #fafafa;
}
</style>
