











// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, watch, onMounted, toRefs, Ref, nextTick } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import i18n from '@/plugins/i18n';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ElementResultsSwitch',
  model: {
    prop: 'state',
    event: 'update'
  },
  props: {
    state: {
      type: Boolean,
      required: true
    },
    firstState: {
      type: String,
      default: 'commons.standards.standard',
      required: false
    },
    secondState: {
      type: String,
      default: 'commons.standards.advanced',
      required: false
    }
  },
  setup(properties, { emit }) {
    const { state } = toRefs(properties);
    const _state: Ref<boolean> = ref(false);

    watch(_state, () => emit('update', _state.value), { immediate: true });

    onMounted(() => {
      nextTick(() => {
        _state.value = state.value;
      });
    });

    return {
      _state
    };
  }
});
