

































































































































































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useRehab } from '@/utils/rehab.utils';
// Import components -------------------------------------------------------------------------------
import ElementResultsTooltip from '@/components/elements/ElementResultsTooltip.vue';
import ElementResultsSwitch from '@/components/elements/ElementResultsSwitch.vue';
import ChartRehabCMJJumpProfile from '@/components/charts/rehab/ChartRehabCMJJumpProfile.vue';
import ChartRehabSingleHopJumpProfile from '@/components/charts/rehab/ChartRehabSingleHopJumpProfile.vue';
import ChartRehabTripleHopJumpProfile from '@/components/charts/rehab/ChartRehabTripleHopJumpProfile.vue';
import ChartRehabSideHopJumpProfile from '@/components/charts/rehab/ChartRehabSideHopJumpProfile.vue';
import ChartRehabGroupGeneralParameters from '@/components/charts/rehab/groups/ChartRehabGroupGeneralParameters.vue';
import ChartRehabGroupGeneralParametersAdvanced from '@/components/charts/rehab/groups/ChartRehabGroupGeneralParametersAdvanced.vue';
import ChartRehabContainerBanner from '@/components/charts/rehab/containers/ChartRehabContainerBanner.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsComparisonJumping',
  components: {
    ElementResultsTooltip,
    ElementResultsSwitch,
    ChartRehabCMJJumpProfile,
    ChartRehabGroupGeneralParameters,
    ChartRehabGroupGeneralParametersAdvanced,
    ChartRehabSingleHopJumpProfile,
    ChartRehabSideHopJumpProfile,
    ChartRehabTripleHopJumpProfile,
    ChartRehabContainerBanner
  },
  props: {
    first: {
      type: Object,
      required: true
    },
    second: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const {
      getMode,
      getActiveWidgets,
      getCMJGeneralParametersSimplifiedData,
      getCMJGeneralParametersAdvancedData,
      getSingleHopGeneralParametersSimplifiedData,
      getSingleHopGeneralParametersAdvancedData,
      getSideHopGeneralParametersSimplifiedData,
      getSideHopGeneralParametersAdvancedData,
      getSideHopCadenceEvolutionChartOptions,
      getTripleHopGeneralParametersSimplifiedData,
      getTripleHopGeneralParametersAdvancedData,
      getTripleHopJumpProfileData
    } = useRehab();

    const isJumpProfileAdvancedMode = ref(false);
    const isGeneralParametersAdvancedMode = ref(false);

    const activeWidgets = computed(() => getActiveWidgets(getMode(properties.first._scenario.key)));

    const simplifiedFirstModeCharts = computed(() => {
      if (getMode(properties.first._scenario.key) === 'CMJ')
        return getCMJGeneralParametersSimplifiedData(properties.first.data.metrics.aggregates);
      if (getMode(properties.first._scenario.key) === 'single-hop')
        return getSingleHopGeneralParametersSimplifiedData(properties.first.data.metrics.aggregates);
      if (getMode(properties.first._scenario.key) === 'side-hop')
        return getSideHopGeneralParametersSimplifiedData(properties.first.data.metrics.aggregates);
      if (getMode(properties.first._scenario.key) === 'triple-hop')
        return getTripleHopGeneralParametersSimplifiedData(properties.first.data.metrics.aggregates);
      return [];
    });
    const advancedFirstModeCharts = computed(() => {
      if (getMode(properties.first._scenario.key) === 'CMJ')
        return getCMJGeneralParametersAdvancedData(properties.first.data.metrics.aggregates);
      if (getMode(properties.first._scenario.key) === 'single-hop')
        return getSingleHopGeneralParametersAdvancedData(properties.first.data.metrics.aggregates);
      if (getMode(properties.first._scenario.key) === 'side-hop')
        return getSideHopGeneralParametersAdvancedData(properties.first.data.metrics.aggregates);
      if (getMode(properties.first._scenario.key) === 'triple-hop')
        return getTripleHopGeneralParametersAdvancedData(
          properties.first.data.metrics.aggregates,
          properties.first.data.metrics.segment
        );
      return [];
    });
    const simplifiedSecondModeCharts = computed(() => {
      if (getMode(properties.first._scenario.key) === 'CMJ')
        return getCMJGeneralParametersSimplifiedData(properties.second.data.metrics.aggregates);
      if (getMode(properties.first._scenario.key) === 'single-hop')
        return getSingleHopGeneralParametersSimplifiedData(properties.second.data.metrics.aggregates);
      if (getMode(properties.first._scenario.key) === 'side-hop')
        return getSideHopGeneralParametersSimplifiedData(properties.second.data.metrics.aggregates);
      if (getMode(properties.first._scenario.key) === 'triple-hop')
        return getTripleHopGeneralParametersSimplifiedData(properties.second.data.metrics.aggregates);
      return [];
    });
    const advancedSecondModeCharts = computed(() => {
      if (getMode(properties.first._scenario.key) === 'CMJ')
        return getCMJGeneralParametersAdvancedData(properties.second.data.metrics.aggregates);
      if (getMode(properties.first._scenario.key) === 'single-hop')
        return getSingleHopGeneralParametersAdvancedData(properties.second.data.metrics.aggregates);
      if (getMode(properties.first._scenario.key) === 'side-hop')
        return getSideHopGeneralParametersAdvancedData(properties.second.data.metrics.aggregates);
      if (getMode(properties.first._scenario.key) === 'triple-hop')
        return getTripleHopGeneralParametersAdvancedData(
          properties.second.data.metrics.aggregates,
          properties.second.data.metrics.segment
        );
      return [];
    });

    const sideHopFirstCadenceEvolutionChartOptions = getSideHopCadenceEvolutionChartOptions(
      properties.first.data.metrics.segment
    );
    const sideHopSecondCadenceEvolutionChartOptions = getSideHopCadenceEvolutionChartOptions(
      properties.second.data.metrics.segment
    );

    return {
      getMode,
      getTripleHopJumpProfileData,
      activeWidgets,
      isGeneralParametersAdvancedMode,
      isJumpProfileAdvancedMode,
      simplifiedFirstModeCharts,
      advancedFirstModeCharts,
      simplifiedSecondModeCharts,
      advancedSecondModeCharts,
      sideHopFirstCadenceEvolutionChartOptions,
      sideHopSecondCadenceEvolutionChartOptions
    };
  }
});
