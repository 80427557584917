




















































































































































































































































































// Import vendors --------------------------------------------------------------
import {
  defineComponent,
  ref,
  reactive,
  computed,
  watch,
  onBeforeUnmount,
  onMounted
} from '@vue/composition-api';
// Import components -----------------------------------------------------------
import PatientResultsComparisonWalking from '@/components/patient/results/comparison/PatientResultsComparisonWalking.vue';
import PatientResultsComparisonWalkingDegraded from '@/components/patient/results/comparison/PatientResultsComparisonWalkingDegraded.vue';
import PatientResultsComparisonRunning from '@/components/patient/results/comparison/PatientResultsComparisonRunning.vue';
import PatientResultsComparisonJumping from '@/components/patient/results/comparison/PatientResultsComparisonJumping.vue';
// Import plugins --------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import helper-----------------------------------------------------------------
import { isDegradedAnalysis } from '@/helpers/helped-functions.helper';
// Import utils -----------------------------------------------------------------
import { useRoute } from '@/utils/router.utils';
import { useAnalytics } from '@/utils/analytics.utils';
import { useRehab } from '@/utils/rehab.utils';
import { usePatient } from '@/utils/patient.utils';
import { getIconByScenario } from '@/utils/icon.utils';
// Import config ---------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// -----------------------------------------------------------------------------

export default defineComponent({
  name: 'PagePatientResultsComparison',
  components: {
    PatientResultsComparisonWalking,
    PatientResultsComparisonWalkingDegraded,
    PatientResultsComparisonRunning,
    PatientResultsComparisonJumping
  },
  setup(_, { root }) {
    const { params, query } = useRoute();
    const { trackSuccess } = useAnalytics();
    const { getMode } = useRehab();
    const patient = usePatient();

    const busModule = usePodocoreModule('bus');
    const requestModule: any = usePodocoreModule('request');

    const analysisList = ref<any[]>([]);
    const analysisListRequest = ref<any>(null);
    const analysisListHasNext = ref(false);

    // -------------------------------------------

    const analysisRequest = ref<any>(null);
    let analysisFirstRequest = ref<any>(null);
    let analysisSecondRequest = ref<any>(null);
    const firstAnalysis: any = ref(null);
    const secondAnalysis: any = ref(null);

    const firstAnalysisByType = computed(() => {
      return secondAnalysis.value
        ? analysisList.value.filter((x: any) => x.cuid !== secondAnalysis.value.cuid)
        : analysisList.value;
    });
    const secondAnalysisByType = computed(() => {
      return firstAnalysis.value
        ? analysisList.value.filter((x: any) => {
            if (x.cuid === firstAnalysis.value.cuid) return false;

            if (firstAnalysis.value._scenario.key.startsWith('walking')) {
              return x._scenario.key.startsWith('walking');
            } else if (firstAnalysis.value._scenario.key.startsWith('running')) {
              return x._scenario.key.startsWith('running');
            } else if (firstAnalysis.value._scenario.key.startsWith('jumping')) {
              return (
                x._scenario.key.startsWith('jumping') &&
                getMode(x._scenario.key) === getMode(firstAnalysis.value._scenario.key)
              );
            }

            return false;
          })
        : [];
    });
    const values: any = reactive({
      first: null,
      second: null
    });

    const haveDegradedWalkingAnalysis = computed(() => {
      return isDegradedAnalysis(values.first) || isDegradedAnalysis(values.second);
    });

    // Configure requests

    function configureAnalysisListRequest(page?: number) {
      if (analysisListRequest.value?.cancel) analysisListRequest.value.cancel();
      analysisListRequest.value = requestModule.useAuthenticatedRequest(`${apiConfig.default}/analysis`, {
        axios: {
          params: {
            patientCuid: params.value.cuid,
            sort: [['createdAt', '-1']],
            page,
            latestStatus: 'completed'
          }
        }
      });
      analysisListRequest.value.request();
    }
    function configureAnalysisRequest(id: string) {
      if (analysisRequest.value?.cancel) analysisRequest.value.cancel();
      analysisRequest.value = requestModule.useAuthenticatedRequest(`${apiConfig.default}/analysis/${id}`);
      analysisRequest.value.request();
    }

    // Additionnal analysis request

    function addValuesByQuery() {
      if (query.value?.firstAnalysis) {
        const analysis = analysisList.value.find((x: any) => x.cuid === query.value.firstAnalysis);
        if (!analysis) configureAnalysisRequest(query.value.firstAnalysis as string);
        firstAnalysis.value = analysis;
      }
      if (query.value?.secondAnalysis) {
        const analysis = analysisList.value.find((x: any) => x.cuid === query.value.secondAnalysis);
        if (!analysis) configureAnalysisRequest(query.value.secondAnalysis as string);
        secondAnalysis.value = analysis;
      }
    }

    //

    function loadMore() {
      configureAnalysisListRequest(analysisListRequest.value.data.nextPage);
    }
    function openExport() {
      busModule.publish(
        busModule.events.openDialog({
          id: 'analysis-export-pdf',
          meta: {
            scenario: firstAnalysis.value._scenario.key,
            analysis: {
              first: values.first.data,
              second: values.second.data
            },
            patient: patient.data,
            mode: 'comparison'
          }
        })
      );
      trackPDFExport();
    }
    function trackPDFExport() {
      trackSuccess('PatientAnalysis/Comparison/Export to PDF', {
        activityType: firstAnalysis.value.activityType
      });
    }
    const getAnalysisIconBgColor = (scenarioKey: string, degraded: boolean) => {
      if (degraded) return 'grey';
      if (scenarioKey.startsWith('walking')) return 'walking';
      if (scenarioKey.startsWith('running')) return 'running';
      return 'rehab';
    };

    // Flags

    const isSuccess = computed(
      () => !analysisRequest.value?.isPending && !analysisRequest.value?.error && analysisRequest.value?.data
    );
    const isPending = computed(() => {
      return (
        analysisFirstRequest.value?.isPending ||
        analysisSecondRequest.value?.isPending ||
        analysisListRequest.value?.isPending
      );
    });

    // Analyses
    watch(
      () => analysisListRequest.value?.data,
      (data) => {
        if (data) {
          analysisList.value.push(...data.docs);
          analysisListHasNext.value = data.hasNextPage;
          addValuesByQuery();
        }
      }
    );

    // Queries
    watch(
      query,
      () => {
        addValuesByQuery();
      },
      { deep: true }
    );

    // First Analysis
    watch(
      firstAnalysis,
      () => {
        if (firstAnalysis.value) {
          // If analysis type change

          if (
            secondAnalysis.value &&
            firstAnalysis.value._scenario.key != secondAnalysis.value._scenario.key
          ) {
            secondAnalysis.value = null;
          }

          if (query.value.firstAnalysis != firstAnalysis.value.cuid) {
            root.$router.push({
              name: 'patient-comparison',
              query: { firstAnalysis: firstAnalysis.value.cuid }
            });
          }

          analysisFirstRequest.value = requestModule.useAuthenticatedRequest(
            `${apiConfig.default}/analysis/${firstAnalysis.value.cuid}`
          );
          analysisFirstRequest.value.request();
        }
      },
      { deep: true }
    );
    // Second Analysis
    watch(
      secondAnalysis,
      (value) => {
        if (secondAnalysis.value) {
          if (query.value.secondAnalysis != secondAnalysis.value.cuid) {
            root.$router.push({
              name: 'patient-comparison',
              query: {
                firstAnalysis: firstAnalysis.value.cuid,
                secondAnalysis: secondAnalysis.value.cuid
              }
            });
          }
          analysisSecondRequest.value = requestModule.useAuthenticatedRequest(
            `${apiConfig.default}/analysis/${secondAnalysis.value.cuid}`
          );
          analysisSecondRequest.value.request();
        }
      },
      { deep: true }
    );

    // First Analysis request
    watch(
      () => analysisFirstRequest.value?.data,
      (data) => {
        if (data) {
          values.first = {
            ...firstAnalysis.value,
            data: analysisFirstRequest.value.data
          };
          if (!analysisList.value.find((x) => x.cuid == query.value.firstAnalysis)) {
            analysisList.value.push(data);
          }
          analysisFirstRequest.value = null;
        }
      }
    );
    // Second Analysis request
    watch(
      () => analysisSecondRequest.value?.data,
      (data) => {
        if (data) {
          values.second = {
            ...secondAnalysis.value,
            data: analysisSecondRequest.value.data
          };
          trackSuccess('PatientAnalysis/Compare', {
            analysisType: secondAnalysis.value._scenario.key
          });
          analysisSecondRequest.value = null;
        }
      }
    );

    // Added analysis request
    watch(
      () => isSuccess.value,
      (value) => {
        if (value) {
          analysisList.value.push(analysisRequest.value.data);
          if (query.value.firstAnalysis && !firstAnalysis.value)
            firstAnalysis.value = analysisRequest.value.data;
          if (query.value.secondAnalysis && !secondAnalysis.value) addValuesByQuery();
          if (query.value.secondAnalysis && !secondAnalysis.value)
            secondAnalysis.value = analysisRequest.value.data;
        }
      }
    );

    onMounted(() => {
      analysisFirstRequest.value = requestModule.useAuthenticatedRequest(
        `${apiConfig.default}/analysis/${query.value.firstAnalysis}`
      );
      analysisFirstRequest.value.request();
      // Load analysis
      configureAnalysisListRequest();
    });
    onBeforeUnmount(() => {
      analysisListRequest.value.cancel();
    });

    return {
      analysisList,
      analysisListHasNext,
      // Values
      analysisRequest,
      values,
      firstAnalysis,
      secondAnalysis,
      haveDegradedWalkingAnalysis,
      firstAnalysisByType,
      secondAnalysisByType,
      // Methods
      loadMore,
      getAnalysisIconBgColor,
      getMode,
      openExport,
      // Flags
      // Helpers
      isDegradedAnalysis,
      isPending,
      // Utils
      getIconByScenario
    };
  }
});
