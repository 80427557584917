

































































































































































































































































































































































































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, reactive } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartWalkingWalkProfile from '@/components/charts/walking/ChartWalkingWalkProfile.vue';
import ChartWalkingWalkProfileAdvanced from '@/components/charts/walking/ChartWalkingWalkProfileAdvanced.vue';
import ChartWalkingGroupBanner from '@/components/charts/walking/groups/ChartWalkingGroupBanner.vue';
import ChartWalkingGaitLine from '@/components/charts/walking/ChartWalkingGaitLine.vue';
import ChartWalkingPropulsionRatio from '@/components/charts/walking/ChartWalkingPropulsionRatio.vue';
import ChartWalkingFootProgressionAngleCircumduction from '@/components/charts/walking/ChartWalkingFootProgressionAngleCircumduction.vue';
import ChartWalkingClearanceSteppage from '@/components/charts/walking/ChartWalkingClearanceSteppage.vue';
import ChartWalkingGroupStepsAngles from '@/components/charts/walking/groups/ChartWalkingGroupStepsAngles.vue';
import ChartWalkingPronationSupinationAnglesAdvanced from '@/components/charts/walking/ChartWalkingPronationSupinationAnglesAdvanced.vue';
//
import ElementChartUnvailable from '@/components/elements/ElementChartUnvailable.vue';
import ElementResultsSwitch from '@/components/elements/ElementResultsSwitch.vue';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsComparisonWalking',
  components: {
    ChartWalkingGroupBanner,
    ChartWalkingWalkProfile,
    ChartWalkingWalkProfileAdvanced,
    ChartWalkingGaitLine,
    ChartWalkingPropulsionRatio,
    ChartWalkingGroupStepsAngles,
    ChartWalkingPronationSupinationAnglesAdvanced,
    ChartWalkingFootProgressionAngleCircumduction,
    ChartWalkingClearanceSteppage,
    ElementChartUnvailable,
    ElementResultsSwitch
  },
  props: {
    first: {
      type: Object,
      required: true
    },
    second: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { data: patient } = usePatient();
    const charts = reactive({
      walkProfile: {
        advanced: false
      },
      gaitLine: {
        relative: false
      },
      angles: {
        advanced: false,
        relative: false
      }
    });

    const integrities = reactive({
      walkingProfile: [
        'walking_stride_length',
        'walking_proportion_stance_time',
        'walking_proportion_swing_time',
        'walking_proportion_double_support_time'
      ],
      walkingProfileAdvanced: [
        'walking_stride_time',
        'walking_stride_length',
        'walking_swing_time',
        'walking_stance_time'
      ],
      gaitline: [
        'walking_gaitline_FFO',
        'walking_gaitline_foot_in',
        'walking_gaitline_foot_out',
        'walking_gaitline_FFI',
        'walking_loading_time',
        'walking_propulsion_time',
        'walking_flatfoot_time'
      ],
      propulsionRatio: ['walking_propulsion_ratio'],
      prosupAngles: [
        'walking_pronation_angle_FFO',
        'walking_pronation_angle_foot_in',
        'walking_pronation_angle_foot_out',
        'walking_pronation_angle_FFI',
        'walking_loading_time',
        'walking_propulsion_time',
        'walking_flatfoot_time'
      ],
      prosupAnglesAdvanced: [
        'walking_pronation_angle_FFO',
        'walking_pronation_angle_foot_in',
        'walking_pronation_angle_foot_out',
        'walking_pronation_angle_FFI'
      ],
      footProgressionAngleCircumduction: ['walking_foot_progression_angle_flat_foot', 'walking_swing_width'],
      clearanceSteppageSimplified: ['walking_plantar_flexion_angle_foot_in'],
      clearanceSteppage: [
        'walking_minimum_toe_clearance',
        'walking_plantar_flexion_angle_foot_in',
        'walking_plantar_flexion_angle_foot_out'
      ]
    });
    function checkIntegrities(chart: any, analysisIndex: 'first' | 'second') {
      for (const integrity of (integrities as any)[chart]) {
        return !!properties[analysisIndex].data.display_metrics.aggregates[integrity];
      }
    }

    return {
      patient,
      charts,
      checkIntegrities
    };
  }
});
