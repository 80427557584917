






























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartStandardGaugeSlider from '@/components/charts/standard/ChartStandardGaugeSlider.vue';
import ChartStandardGaugeSections from '@/components/charts/standard/ChartStandardGaugeSections.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingGroupBanner',
  components: {
    ChartStandardGaugeSlider,
    ChartStandardGaugeSections
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    degraded: {
      type: Boolean
    }
  },
  setup() {
    const { isImperial } = useUnit().currentUnit();
    const speedUnit = computed(() => getUnit(EValueTypeUnit.Speed));

    return {
      isImperial,
      speedUnit
    };
  }
});
