




































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartRunningSpeedCadence from '@/components/charts/running/ChartRunningSpeedCadence.vue';
import ChartRunningComparisonTable from '@/components/charts/running/ChartRunningComparisonTable.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsComparisonRunning',
  components: {
    ChartRunningSpeedCadence,
    ChartRunningComparisonTable
    // ElementChartUnvailable
  },
  props: {
    first: {
      type: Object,
      required: true
    },
    second: {
      type: Object,
      required: true
    }
  }
});
